<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6">
                
                <v-select
                v-model="selectedItem.id"
                :items="reportes"
                item-text="title"
                item-value="id"
                label="Reportes"
                prepend-icon="mdi-account-circle"
              ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12" >
                <listado_de_cantidad_eventos_por_gestion_sector_nuevos_y_reingresos
                  v-if="selectedItem.id === 1"
                ></listado_de_cantidad_eventos_por_gestion_sector_nuevos_y_reingresos>

                <listado_de_contratos_completos_e_incompletos
                  v-if="selectedItem.id === 2"
                ></listado_de_contratos_completos_e_incompletos>

                <listado_de_gestiones_atrasadas_segun_evento
                  v-if="selectedItem.id === 3"
                ></listado_de_gestiones_atrasadas_segun_evento>

                <listado_de_gestiones_con_complementos_pendientes_y_compromiso_de_pago
                  v-if="selectedItem.id === 4"
                ></listado_de_gestiones_con_complementos_pendientes_y_compromiso_de_pago>

                <listado_de_gestiones_por_sector_color_y_autorizacion
                  v-if="selectedItem.id === 5"
                ></listado_de_gestiones_por_sector_color_y_autorizacion>

                <listado_de_informacion_ingresada_por_supervisora
                  v-if="selectedItem.id === 6"
                ></listado_de_informacion_ingresada_por_supervisora>

                <listado_de_evaluaciones_eliminadas_movil
                  v-if="selectedItem.id === 7"
                ></listado_de_evaluaciones_eliminadas_movil>

                <listado_de_gestiones_ingresadas_por_sector_en_proceso_aprobada_denegada_y_cancelada
                  v-if="selectedItem.id === 8"
                ></listado_de_gestiones_ingresadas_por_sector_en_proceso_aprobada_denegada_y_cancelada>

                <listado_de_usuarios_por_bandeja
                  v-if="selectedItem.id === 9"
                ></listado_de_usuarios_por_bandeja>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import listado_de_cantidad_eventos_por_gestion_sector_nuevos_y_reingresos from './listado_de_cantidad_eventos_por_gestion_sector_nuevos_y_reingresos.vue';
import listado_de_contratos_completos_e_incompletos from './listado_de_contratos_completos_e_incompletos.vue';
import listado_de_gestiones_atrasadas_segun_evento from './listado_de_gestiones_atrasadas_segun_evento.vue';
import listado_de_gestiones_con_complementos_pendientes_y_compromiso_de_pago from './listado_de_gestiones_con_complementos_pendientes_y_compromiso_de_pago.vue';
import listado_de_gestiones_por_sector_color_y_autorizacion from './listado_de_gestiones_por_sector_color_y_autorizacion.vue';
import listado_de_informacion_ingresada_por_supervisora from './listado_de_informacion_ingresada_por_supervisora.vue';
import listado_de_evaluaciones_eliminadas_movil from './listado_de_evaluaciones_eliminadas_movil.vue';
import listado_de_gestiones_ingresadas_por_sector_en_proceso_aprobada_denegada_y_cancelada from './listado_de_gestiones_ingresadas_por_sector_en_proceso_aprobada_denegada_y_cancelada.vue';
import listado_de_usuarios_por_bandeja from './listado_de_usuarios_por_bandeja.vue';

export default {
  components: {
    listado_de_cantidad_eventos_por_gestion_sector_nuevos_y_reingresos,
    listado_de_contratos_completos_e_incompletos,
    listado_de_gestiones_atrasadas_segun_evento,
    listado_de_gestiones_con_complementos_pendientes_y_compromiso_de_pago,
    listado_de_gestiones_por_sector_color_y_autorizacion,
    listado_de_informacion_ingresada_por_supervisora,
    listado_de_evaluaciones_eliminadas_movil,
    listado_de_gestiones_ingresadas_por_sector_en_proceso_aprobada_denegada_y_cancelada,
    listado_de_usuarios_por_bandeja,
  },
  data() {
    return {
      selectedItem: {
          id: 1,
          title:
            'LISTADO DE CANTIDAD EVENTOS POR GESTION SECTOR NUEVOS Y REINGRESOS',
          subtitle: '...',
        },
      reportes: [
        { header: 'REPORTES PRECREDITPRO' },
        {
          id: 1,
          title:
            'LISTADO DE CANTIDAD EVENTOS POR GESTION SECTOR NUEVOS Y REINGRESOS',
          subtitle: '...',
        },
        {
          id: 2,
          title: 'LISTADO DE CONTRATOS COMPLETOS E INCOMPLETOS',
          subtitle: '...',
        },
        {
          id: 3,
          title: 'LISTADO DE GESTIONES ATRASADAS SEGUN EVENTO',
          subtitle: '...',
        },
        {
          id: 4,
          title:
            'LISTADO DE GESTIONES CON COMPLEMENTOS PENDIENTES Y COMPROMISO DE PAGO',
          subtitle: '...',
        },
        {
          id: 5,
          title: 'LISTADO DE GESTIONES POR SECTOR, COLOR Y AUTORIZACION',
          subtitle: '...',
        },
        {
          id: 6,
          title: 'LISTADO DE INFORMACIÓN INGRESADA POR SUPERVISORA',
          subtitle: '...',
        },
        { header: 'REPORTES TOTALIZADOS' },
        {
          id: 7,
          title: 'LISTADO DE EVALUACIONES ELIMINADAS MÓVIL',
          subtitle: '...',
        },
        {
          id: 8,
          title:
            'LISTADO DE GESTIONES INGRESADAS POR SECTOR (EN PROCESO, APROBADA, DENEGADA Y CANCELADA)',
          subtitle: '...',
        },
        { id: 9, title: 'LISTADO DE USUARIOS POR BANDEJA', subtitle: '...' },
      ],
    };
  },

};
</script>